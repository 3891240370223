import React from 'react'
import { Link, useIntl } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import SEO from '../../components/seo'
import PressRelease from '../../components/PressRelease'

import messages from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import Image from '../../images/news/press-release-chatgpt.jpg'

import { newsList } from '../../components/NewsPage/data'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleChatGPT)}
        imageAlt={formatMessage(messages.TitleChatGPT)}
        description={formatMessage(metaDescription.NewsChatGPTDescription)}
        image={Image}
        lang={locale}
      />
      <PressRelease
        article={newsList.chatgpt}
        articleLinks={{
          freshms: (
            <Link to="/products/freshms/">
              freshMS
            </Link>
          )
        }}
      />
    </Layout>
  )
}

export default IndexPage
